
section.promotions {
  text-align: center;
  background-color: #f8f9fa!important;
}
section.promotions h2 {
  margin-top: 0;
  margin-bottom: 25px;
}
section.promotions h2 i {
  color: #dd4b39;
}