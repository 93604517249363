section.features {
  text-align: center;
  background-color:#17a2b8!important;
}
section.features h2 {
  margin-top: 0;
  margin-bottom: 25px;
}
section.features h2 i {
  color: #dd4b39;
}

section.brand-your-platform {
  text-align: center;
  background-color:#17a2b8!important;
}
section.brand-your-platform h2 {
  margin-top: 0;
  margin-bottom: 25px;
}
section.brand-your-platform h2 i {
  color: #dd4b39;
}

section.manage-your-products {
  /* text-align: center; */
  background-color:#ffffff!important;
}
section.manage-your-products h2 {
  margin-top: 0;
  margin-bottom: 25px;
}
section.manage-your-products h2 i {
  color: #dd4b39;
}

section.streamline-your-orders {
  /* text-align: center; */
  background-color:#fdcc52!important;
}
section.streamline-your-orders h2 {
  margin-top: 0;
  margin-bottom: 25px;
}
section.streamline-your-orders h2 i {
  color: #dd4b39;
}

section.monitor-and-analyze {
  /* text-align: center; */
  background-color:#2ed7e8!important;
}
section.monitor-and-analyze h2 {
  margin-top: 0;
  margin-bottom: 25px;
}
section.monitor-and-analyze h2 i {
  color: #dd4b39;
}

section.own-and-grow-your-consumer-base {
  /* text-align: center; */
  background-color:#ffffff!important;
}
section.own-and-grow-your-consumer-base h2 {
  margin-top: 0;
  margin-bottom: 25px;
}
section.own-and-grow-your-consumer-base h2 i {
  color: #dd4b39;
}

section.supervise-your-branches-and-team {
  /* text-align: center; */
  background-color:#2ed7e8!important;
}
section.supervise-your-branches-and-team h2 {
  margin-top: 0;
  margin-bottom: 25px;
}
section.supervise-your-branches-and-team h2 i {
  color: #dd4b39;
}