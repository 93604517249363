section.dashboard {
  text-align: center;
  background-color: #09e69a!important;
}
section.dashboard h2 {
  margin-top: 0;
  margin-bottom: 25px;
}
section.dashboard h2 i {
  color: #dd4b39;
}