/*
 *	HTML5 Device Mockups
 *
 *	By:
 *		Tomi Hiltunen
 *		tomi@mitakuuluu.fi
 *		http://fi.linkedin.com/in/tomihiltunen/
 *
 *		Angelos Arnis
 *		http://fi.linkedin.com/in/angelosarnis/
 *
 *	Inluded in this package:
 *		- iPhone5 (black/white, portrait/landscape)
 *		- iPad2 (black/white, portrait/landscape)
 *		- iMac
 *		- MacBook Pro (retina model)
 *		- Nexus 7
 *		- Lumia 920
 *		- Microsoft Surface
 *		- Galaxy S3
 *
 *  Added by ben182 (@beno182):
 *		- iPhone 6
 *  	- iPhone 6 Plus
 *		- iPhone SE
 *		- iPad Air
 *		- iPad Air 2
 *		- iPad Pro
 *		- Macbook 2015
 *
 *
 */

 /* GENERAL STYLE */

.device-mockup {
	position: relative;
	width: 100%;
	padding-bottom: 61.775701%;
}

	.device-mockup > .device {
		position: absolute;
		top: 0; bottom: 0; left: 0; right: 0;
		width: 100%; height: 100%;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-image: url("macbook/macbook.png");
	}

		.device-mockup > .device > .screen {
			position: absolute;
			top:    11.0438729%;
			bottom: 14.6747352%;
			left:   13.364486%;
			right:  13.364486%;
			overflow: hidden;
		}

		.device-mockup > .device > .button {
			position: absolute;
			top: 0; left: 0;
			display: none;
			overflow: hidden;
			border-radius: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			cursor: pointer;
		}


/* DEVICES */


/* iMac */
.device-mockup.imac {
	padding-bottom: 81.230769%;
}

.device-mockup.imac > .device {
	background-image: url("imac/imac.png");
}

.device-mockup.imac > .device > .screen {
	top: 8.20707071%; bottom: 31.6919192%; left: 6.61538462%; right: 6.61538462%;
}


/* MacBook Pro */
.device-mockup.macbook {
	padding-bottom: 61.775701%;
}

.device-mockup.macbook > .device {
	background-image: url("macbook/macbook.png");
}

.device-mockup.macbook > .device > .screen {
	top: 11.0438729%; bottom: 14.6747352%; left: 13.364486%; right: 13.364486%;
}

/* MacBook 2015 */
.device-mockup.macbook_2015 {
	padding-bottom: 57.50%;
}

.device-mockup.macbook_2015 > .device,
.device-mockup.macbook_2015.grey > .device {
	background-image: url("macbook_2015/grey.png");
}

.device-mockup.macbook_2015 > .device,
.device-mockup.macbook_2015.silver > .device {
	background-image: url("macbook_2015/silver.png");
}

.device-mockup.macbook_2015 > .device,
.device-mockup.macbook_2015.gold > .device {
	background-image: url("macbook_2015/gold.png");
}

.device-mockup.macbook_2015 > .device > .screen {
    top: 7.5%; bottom: 10.9%; left: 12.46%; right: 12.56%;
}


/* iPhone 5 */
.device-mockup.iphone5,
.device-mockup.iphone5.portrait {
	padding-bottom: 210.57%;
}

.device-mockup.iphone5.landscape {
	padding-bottom: 47.49%;
}

.device-mockup.iphone5 > .device,
.device-mockup.iphone5.black > .device,
.device-mockup.iphone5.portrait.black > .device {
	background-image: url("iphone5/iphone5_port_black.png");
}

.device-mockup.iphone5.landscape > .device,
.device-mockup.iphone5.landscape.black > .device {
	background-image: url("iphone5/iphone5_land_black.png");
}

.device-mockup.iphone5.white > .device,
.device-mockup.iphone5.portrait.white > .device {
	background-image: url("iphone5/iphone5_port_white.png");
}

.device-mockup.iphone5.landscape.white > .device {
	background-image: url("iphone5/iphone5_land_white.png");
}

.device-mockup.iphone5 > .device > .screen,
.device-mockup.iphone5.portrait > .device > .screen {
    top: 14.78%; bottom: 13.78%; left: 8.77%; right: 7.77%;
}

.device-mockup.iphone5.landscape > .device > .screen {
    top: 7.422488%; bottom: 8.422488%; left: 14.919127%; right: 14.019127%;
}

.device-mockup.iphone5 > .device > .button,
.device-mockup.iphone5.portrait > .device > .button {
	display: block;
    top: 88.3%; bottom: 2.5%; left: 40%; right: 40%;
}

.device-mockup.iphone5.landscape > .device > .button {
	display: block;
    top: 40%; bottom: 41%; left: 88.4%; right: 2.3%;
}

/* iPhone SE */
.device-mockup.iphone_se,
.device-mockup.iphone_se.portrait {
	padding-bottom: 209.83%;
}

.device-mockup.iphone_se.landscape {
	padding-bottom: 47.66%;
}

.device-mockup.iphone_se > .device,
.device-mockup.iphone_se.black > .device,
.device-mockup.iphone_se.portrait.black > .device {
	background-image: url("iphone_se/iphone_se_port_black.png");
}

.device-mockup.iphone_se.landscape > .device,
.device-mockup.iphone_se.landscape.black > .device {
	background-image: url("iphone_se/iphone_se_land_black.png");
}

.device-mockup.iphone_se.white > .device,
.device-mockup.iphone_se.portrait.white > .device {
	background-image: url("iphone_se/iphone_se_port_white.png");
}

.device-mockup.iphone_se.landscape.white > .device {
	background-image: url("iphone_se/iphone_se_land_white.png");
}

.device-mockup.iphone_se.gold > .device,
.device-mockup.iphone_se.portrait.gold > .device {
	background-image: url("iphone_se/iphone_se_port_gold.png");
}

.device-mockup.iphone_se.landscape.gold > .device {
	background-image: url("iphone_se/iphone_se_land_gold.png");
}

.device-mockup.iphone_se.rose > .device,
.device-mockup.iphone_se.portrait.rose > .device {
	background-image: url("iphone_se/iphone_se_port_rose.png");
}

.device-mockup.iphone_se.landscape.rose > .device {
	background-image: url("iphone_se/iphone_se_land_rose.png");
}

.device-mockup.iphone_se > .device > .screen,
.device-mockup.iphone_se.portrait > .device > .screen {
	top: 16.28%; bottom: 16.28%; left: 11.77%; right: 11.77%;
}

.device-mockup.iphone_se.landscape > .device > .screen {
	top: 11.722488%; bottom: 11.722488%; left: 16.0191273%; right: 16.0191273%;
}

.device-mockup.iphone_se > .device > .button,
.device-mockup.iphone_se.portrait > .device > .button {
	display: block;
	top: 86.3%; bottom: 5.5%; left:  42%; right: 42%;
}

.device-mockup.iphone_se.landscape > .device > .button {
	display: block;
	top: 42%; bottom: 42%; left:  86.4%; right: 5.1%;
}

/* iPhone 6 */
.device-mockup.iphone6,
.device-mockup.iphone6.portrait {
	padding-bottom: 207.19%;
}

.device-mockup.iphone6.landscape {
	padding-bottom: 48.76%;
}

.device-mockup.iphone6 > .device,
.device-mockup.iphone6.black > .device,
.device-mockup.iphone6.portrait.black > .device {
	background-image: url("iphone_6/iphone_6_port_black.png");
}

.device-mockup.iphone6.landscape > .device,
.device-mockup.iphone6.landscape.black > .device {
	background-image: url("iphone_6/iphone_6_land_black.png");
}

.device-mockup.iphone6.white > .device,
.device-mockup.iphone6.portrait.white > .device {
	background-image: url("iphone_6/iphone_6_port_white.png");
}

.device-mockup.iphone6.landscape.white > .device {
	background-image: url("iphone_6/iphone_6_land_white.png");
}

.device-mockup.iphone6.gold > .device,
.device-mockup.iphone6.portrait.gold > .device {
	background-image: url("iphone_6/iphone_6_port_gold.png");
}

.device-mockup.iphone6.landscape.gold > .device {
	background-image: url("iphone_6/iphone_6_land_gold.png");
}

.device-mockup.iphone6 > .device > .screen,
.device-mockup.iphone6.portrait > .device > .screen {
	top: 12.58%; bottom: 12.88%; left: 6.77%; right: 6.77%;
}

.device-mockup.iphone6.landscape > .device > .screen {
	top: 6.77%; bottom: 6.77%; left: 12.58%; right: 12.88%;
}

.device-mockup.iphone6 > .device > .button,
.device-mockup.iphone6.portrait > .device > .button {
	display: block;
	top: 88.6%; bottom: 3.2%; left: 42%; right: 42%;
}

.device-mockup.iphone6.landscape > .device > .button {
	display: block;
	top: 42%; bottom: 42%; left:  88.6%; right: 3.2%;
}

/* iPhone 6 Plus*/
.device-mockup.iphone6_plus,
.device-mockup.iphone6_plus.portrait {
	padding-bottom: 201.76%;
}

.device-mockup.iphone6_plus.landscape {
	padding-bottom: 49.56%;
}

.device-mockup.iphone6_plus > .device,
.device-mockup.iphone6_plus.black > .device,
.device-mockup.iphone6_plus.portrait.black > .device {
	background-image: url("iphone_6_plus/iphone_6_plus_black_port.png");
}

.device-mockup.iphone6_plus.landscape > .device,
.device-mockup.iphone6_plus.landscape.black > .device {
	background-image: url("iphone_6_plus/iphone_6_plus_black_land.png");
}

.device-mockup.iphone6_plus.white > .device,
.device-mockup.iphone6_plus.portrait.white > .device {
	background-image: url("iphone_6_plus/iphone_6_plus_white_port.png");
}

.device-mockup.iphone6_plus.landscape.white > .device {
	background-image: url("iphone_6_plus/iphone_6_plus_white_land.png");
}

.device-mockup.iphone6_plus.gold > .device,
.device-mockup.iphone6_plus.portrait.gold > .device {
	background-image: url("iphone_6_plus/iphone_6_plus_gold_port.png");
}

.device-mockup.iphone6_plus.landscape.gold > .device {
	background-image: url("iphone_6_plus/iphone_6_plus_gold_land.png");
}

.device-mockup.iphone6_plus > .device > .screen,
.device-mockup.iphone6_plus.portrait > .device > .screen {
    top: 11.58%; bottom: 11.58%; left: 5.97%; right: 5.97%;
}

.device-mockup.iphone6_plus.landscape > .device > .screen {
    top: 5.97%; bottom: 5.97%; left: 11.38%; right: 11.58%;
}

.device-mockup.iphone6_plus > .device > .button,
.device-mockup.iphone6_plus.portrait > .device > .button {
	display: block;
    top: 89.9%; bottom: 3.2%; left: 43%; right: 43%;
}

.device-mockup.iphone6_plus.landscape > .device > .button {
	display: block;
	top: 43%; bottom: 43%; left: 89.9%; right: 3.2%;
}

/* iPad */
.device-mockup.ipad,
.device-mockup.ipad.portrait {
	padding-bottom: 128.406276%;
}

.device-mockup.ipad.landscape {
	padding-bottom: 79.9086758%;
}

.device-mockup.ipad > .device,
.device-mockup.ipad.black > .device,
.device-mockup.ipad.portrait.black > .device {
	background-image: url("ipad/ipad_port_black.png");
}

.device-mockup.ipad.white > .device,
.device-mockup.ipad.portrait.white > .device {
	background-image: url("ipad/ipad_port_white.png");
}

.device-mockup.ipad.landscape > .device,
.device-mockup.ipad.landscape.black > .device {
	background-image: url("ipad/ipad_land_black.png");
}

.device-mockup.ipad.landscape.white > .device {
	background-image: url("ipad/ipad_land_white.png");
}

.device-mockup.ipad > .device > .screen,
.device-mockup.ipad.portrait > .device > .screen {
	top: 12.025723%; bottom: 12.154341%; left: 13.45995%; right: 13.45995%;
}

.device-mockup.ipad.landscape > .device > .screen {
	top: 13.87755102%; bottom: 13.87755102%; left: 11.5459883%; right: 11.5459883%;
}

.device-mockup.ipad > .device > .button,
.device-mockup.ipad.portrait > .device > .button {
	display: block;
	top: 90.2%; bottom: 5.5%; left: 47.3%; right: 47.3%;
}

.device-mockup.ipad.landscape > .device > .button {
	display: block;
	top: 47.3%; bottom: 47.3%; left: 90.8%; right: 4.9%;
}

/* iPad Air */
.device-mockup.ipad_air,
.device-mockup.ipad_air.portrait {
	padding-bottom: 141.27%;
}

.device-mockup.ipad_air.landscape {
	padding-bottom: 70.79%;
}

.device-mockup.ipad_air > .device,
.device-mockup.ipad_air.grey > .device,
.device-mockup.ipad_air.portrait.grey > .device {
	background-image: url("ipad_air/ipad_air_gray_port.png");
}

.device-mockup.ipad_air.silver > .device,
.device-mockup.ipad_air.portrait.silver > .device {
	background-image: url("ipad_air/ipad_air_silver_port.png");
}

.device-mockup.ipad_air.landscape > .device,
.device-mockup.ipad_air.landscape.grey > .device {
	background-image: url("ipad_air/ipad_air_gray_land.png");
}

.device-mockup.ipad_air.landscape.silver > .device {
	background-image: url("ipad_air/ipad_air_silver_land.png");
}

.device-mockup.ipad_air > .device > .screen,
.device-mockup.ipad_air.portrait > .device > .screen {
	top: 9.025723%; bottom: 9.154341%; left: 7.45995%; right: 6.45995%;
}

.device-mockup.ipad_air.landscape > .device > .screen {
    top: 6.877551%; bottom: 6.877551%; left: 9.545988%; right: 9.545988%;
}

.device-mockup.ipad_air > .device > .button,
.device-mockup.ipad_air.portrait > .device > .button {
	display: block;
    top: 93.2%; bottom: 2.5%; left: 47.3%; right: 47.3%;
}

.device-mockup.ipad_air.landscape > .device > .button {
	display: block;
	top: 47.3%; bottom: 47.3%; left: 93.1%; right: 2.1%;
}

/* iPad Air 2 */
.device-mockup.ipad_air_2,
.device-mockup.ipad_air_2.portrait {
    padding-bottom: 141.38%;
}

.device-mockup.ipad_air_2.landscape {
	padding-bottom: 70.73%;
}

.device-mockup.ipad_air_2 > .device,
.device-mockup.ipad_air_2.grey > .device,
.device-mockup.ipad_air_2.portrait.grey > .device {
	background-image: url("ipad_air_2/ipad_air_2_gray_port.png");
}

.device-mockup.ipad_air_2.silver > .device,
.device-mockup.ipad_air_2.portrait.silver > .device {
	background-image: url("ipad_air_2/ipad_air_2_silver_port.png");
}

.device-mockup.ipad_air_2.gold > .device,
.device-mockup.ipad_air_2.portrait.gold > .device {
	background-image: url("ipad_air_2/ipad_air_2_gold_port.png");
}

.device-mockup.ipad_air_2.landscape > .device,
.device-mockup.ipad_air_2.landscape.grey > .device {
	background-image: url("ipad_air_2/ipad_air_2_gray_land.png");
}

.device-mockup.ipad_air_2.landscape.silver > .device {
	background-image: url("ipad_air_2/ipad_air_2_silver_land.png");
}

.device-mockup.ipad_air_2.landscape.gold > .device {
	background-image: url("ipad_air_2/ipad_air_2_gold_land.png");
}

.device-mockup.ipad_air_2 > .device > .screen,
.device-mockup.ipad_air_2.portrait > .device > .screen {
	top: 9.025723%; bottom: 8.854341%; left: 6.15995%; right: 6.15995%;
}

.device-mockup.ipad_air_2.landscape > .device > .screen {
    top: 6.277551%; bottom: 6.277551%; left: 8.545988%; right: 8.545988%;
}

.device-mockup.ipad_air_2 > .device > .button,
.device-mockup.ipad_air_2.portrait > .device > .button {
	display: block;
    top: 93.2%; bottom: 2.1%; left: 46.3%; right: 46.5%;
}

.device-mockup.ipad_air_2.landscape > .device > .button {
	display: block;
	top: 46.5%; bottom: 46.8%; left: 93.5%; right: 1.9%;
}

/* iPad Pro */
.device-mockup.ipad_pro,
.device-mockup.ipad_pro.portrait {
    padding-bottom: 138.63%;
}

.device-mockup.ipad_pro.landscape {
	padding-bottom: 72.14%;
}

.device-mockup.ipad_pro > .device,
.device-mockup.ipad_pro.grey > .device,
.device-mockup.ipad_pro.portrait.grey > .device {
	background-image: url("ipad_pro/ipad_pro_port_black.png");
}

.device-mockup.ipad_pro.silver > .device,
.device-mockup.ipad_pro.portrait.silver > .device {
	background-image: url("ipad_pro/ipad_pro_port_silver.png");
}

.device-mockup.ipad_pro.gold > .device,
.device-mockup.ipad_pro.portrait.gold > .device {
	background-image: url("ipad_pro/ipad_pro_port_gold.png");
}

.device-mockup.ipad_pro.landscape > .device,
.device-mockup.ipad_pro.landscape.grey > .device {
	background-image: url("ipad_pro/ipad_pro_land_black.png");
}

.device-mockup.ipad_pro.landscape.silver > .device {
	background-image: url("ipad_pro/ipad_pro_land_silver.png");
}

.device-mockup.ipad_pro.landscape.gold > .device {
	background-image: url("ipad_pro/ipad_pro_land_gold.png");
}

.device-mockup.ipad_pro > .device > .screen,
.device-mockup.ipad_pro.portrait > .device > .screen {
    top: 7.025723%; bottom: 6.854341%; left: 5.15995%; right: 5.15995%;
}

.device-mockup.ipad_pro.landscape > .device > .screen {
    top: 5.177551%; bottom: 5.177551%; left: 6.945988%; right: 6.945988%;
}

.device-mockup.ipad_pro > .device > .button,
.device-mockup.ipad_pro.portrait > .device > .button {
	display: block;
    top: 94.9%; bottom: 1.5%; left: 47.3%; right: 47.1%;
}

.device-mockup.ipad_pro.landscape > .device > .button {
	display: block;
    top: 47.5%; bottom: 47.5%; left: 94.8%; right: 1.5%;
}


/* Galaxy S3 */
.device-mockup.galaxy_s3,
.device-mockup.galaxy_s3.portrait {
	padding-bottom: 178.787879%;
}

.device-mockup.galaxy_s3.landscape {
	padding-bottom: 55.9322034%;
}

.device-mockup.galaxy_s3 > .device,
.device-mockup.galaxy_s3.black > .device,
.device-mockup.galaxy_s3.portrait.black > .device {
	background-image: url("galaxy_s3/s3_port_black.png");
}

.device-mockup.galaxy_s3.landscape > .device,
.device-mockup.galaxy_s3.landscape.black > .device {
	background-image: url("galaxy_s3/s3_land_black.png");
}

.device-mockup.galaxy_s3.white > .device,
.device-mockup.galaxy_s3.portrait.white > .device {
	background-image: url("galaxy_s3/s3_port_white.png");
}

.device-mockup.galaxy_s3.landscape.white > .device {
	background-image: url("galaxy_s3/s3_land_white.png");
}

.device-mockup.galaxy_s3 > .device > .screen,
.device-mockup.galaxy_s3.portrait > .device > .screen {
	top: 12.3728814%; bottom: 15.5367232%; left: 13.8383838%; right: 13.8383838%;
}

.device-mockup.galaxy_s3.landscape > .device > .screen {
	top: 13.8383838%; bottom: 13.8383838%; left: 12.3728814%; right: 15.5367232%;
}


/* Galaxy S5 */
.device-mockup.galaxy_s5,
.device-mockup.galaxy_s5.portrait {
  padding-bottom: 194.188862%;
}

.device-mockup.galaxy_s5.landscape {
  padding-bottom: 51.496259%;
}

.device-mockup.galaxy_s5 > .device,
.device-mockup.galaxy_s5.black > .device,
.device-mockup.galaxy_s5.portrait.black > .device {
  background-image: url("galaxy_s5/galaxy_s5_port_black.png");
}

.device-mockup.galaxy_s5.landscape > .device,
.device-mockup.galaxy_s5.landscape.black > .device {
  background-image: url("galaxy_s5/galaxy_s5_land_black.png");
}

.device-mockup.galaxy_s5.white > .device,
.device-mockup.galaxy_s5.portrait.white > .device {
  background-image: url("galaxy_s5/galaxy_s5_port_white.png");
}

.device-mockup.galaxy_s5.landscape.white > .device {
  background-image: url("galaxy_s5/galaxy_s5_land_white.png");
}

.device-mockup.galaxy_s5 > .device > .screen,
.device-mockup.galaxy_s5.portrait > .device > .screen {
  top: 10.2%; bottom: 10.1%; left: 6.3%; right: 6.4%;
}

.device-mockup.galaxy_s5.landscape > .device > .screen {
  top: 6.6%; bottom: 6.2%; left: 10.15%; right: 10%;
}


/* Galaxy Tab4 */
.device-mockup.galaxy_tab4,
.device-mockup.galaxy_tab4.landscape {
  padding-bottom: 72.689464%;
}

.device-mockup.galaxy_tab4 > .device,
.device-mockup.galaxy_tab4.black > .device,
.device-mockup.galaxy_tab4.landscape.black > .device {
  background-image: url("galaxy_tab4/galaxy_tab4_land_black.png");
}

.device-mockup.galaxy_tab4.white > .device,
.device-mockup.galaxy_tab4.landscape.white > .device {
  background-image: url("galaxy_tab4/galaxy_tab4_land_white.png");
}

.device-mockup.galaxy_tab4 > .device > .screen,
.device-mockup.galaxy_tab4.landscape > .device > .screen {
  top: 11.5%; bottom: 11.5%; left: 5.4%; right: 5.2%;
}


/* Lumia 920 */
.device-mockup.lumia920,
.device-mockup.lumia920.portrait {
	padding-bottom: 172.796353%;
}

.device-mockup.lumia920.landscape {
	padding-bottom: 56.3330381%;
}

.device-mockup.lumia920 > .device,
.device-mockup.lumia920.portrait > .device {
	background-image: url("lumia920/lumia920_port.png");
}

.device-mockup.lumia920.landscape > .device {
	background-image: url("lumia920/lumia920_land.png");
}
.device-mockup.lumia920 > .device > .screen,
.device-mockup.lumia920.portrait > .device > .screen {
	top: 10.6420405%; bottom: 18.9973615%; left: 13.5258359%; right: 13.5258359%;
}

.device-mockup.lumia920.landscape > .device > .screen {
	top: 12.2641509%; bottom: 12.2641509%; left: 10.5403012%; right: 18.6005314%;
}


/* Nexus 7 */
.device-mockup.nexus7,
.device-mockup.nexus7.portrait {
	padding-bottom: 156.521739%;
}

.device-mockup.nexus7.landscape {
	padding-bottom: 63.6678201%;
}

.device-mockup.nexus7 > .device,
.device-mockup.nexus7.portrait > .device {
	background-image: url("nexus7/nexus7_port.png");
}

.device-mockup.nexus7.landscape > .device {
	background-image: url("nexus7/nexus7_land.png");
}

.device-mockup.nexus7 > .device > .screen,
.device-mockup.nexus7.portrait > .device > .screen {
	top: 13.1365741%; bottom: 12.7893519%; left: 13.7681159%; right: 13.7681159%;
}

.device-mockup.nexus7.landscape > .device > .screen {
	top: 13.7681159%; bottom: 13.7681159%; left: 13.0911188%; right: 13.0911188%;
}


/* Microsoft Surface */
.device-mockup.surface,
.device-mockup.surface.landscape {
	padding-bottom: 64.7584973%;
}

.device-mockup.surface > .device,
.device-mockup.surface.landscape > .device {
	background-image: url("surface/surface.png");
}

.device-mockup.surface > .device > .screen,
.device-mockup.surface.landscape > .device > .screen {
	top: 14.5488029%; bottom: 14.9171271%; left: 9.36195587%; right: 9.36195587%;
}

/* Samsung TV */
.device-mockup.samsung_tv,
.device-mockup.samsung_tv.landscape {
	padding-bottom: 64.68%;
}

.device-mockup.samsung_tv > .device,
.device-mockup.samsung_tv.landscape > .device {
	background-image: url("samsung_tv/samsung_tv.png");
}

.device-mockup.samsung_tv > .device > .screen,
.device-mockup.samsung_tv.landscape > .device > .screen {
    top: 2.148803%; bottom: 12.817127%; left: 1.261956%; right: 1.361956%;
}
