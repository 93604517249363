section.testimonials {
  text-align: center;
  background-color:#28a745!important;
}
section.testimonials h2 {
  margin-top: 0;
  margin-bottom: 25px;
}
section.testimonials h2 i {
  color: #dd4b39;
}
